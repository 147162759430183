import { useState } from 'react'
import MainVideoModal from '@/components/HomePage/MainVideoModal/MainVideoModal'
import stl from './MainVideo.module.scss'

export default function MainVideo() {
  const [modalVisibility, setModalVisibility] = useState(false)

  return (
    <>
      <div className={ stl.box }>
        <h3 className={ stl.title }>
          За минуту просто<br/>
          и ясно о том,<br/>
          как пойдёт работа
        </h3>

        <button
          className={ stl.thumb }
          onClick={ () => {
            document.body.classList.add('lock')
            setModalVisibility(true)
          } }
        >
          <img
            src="img/mainvideo-thumb.webp"
            alt="Превью видео"
          />
        </button>
      </div>

      { modalVisibility && (
        <MainVideoModal
          onClose={ () => setModalVisibility(false) }
        />
      )}
    </>

  )
}
