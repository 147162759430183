import stl from './ServiceTimeline.module.scss'

const ServiceTimeline = ({ className, steps = [] }) => {

  return (
    <ol className={ `${stl.box} ${className ? className : ''}` }>
      {
        steps.map((step, index) => {
          const [name] = [...step]

          return (
            <li key={ `step-${index}` } className={ stl.step }>
              <span className={ stl.step_count }></span>
              <span className={ stl.step_name }>{ name }</span>
            </li>
          )
        })
      }
    </ol>
  )
}

export default ServiceTimeline