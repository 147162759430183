import stl from './Banner.module.scss'

const Banner = () => {

  return (
    <section className={ stl.box }>
      <div className={ stl.head }>
        <h1 className={ stl.title }>
          Разработка и сопровождение<br/>
          сайтов любой сложности
        </h1>

        <span className={ stl.subtitle }>Прозрачно, быстро&nbsp;— с оплатой за результат</span>
      </div>

      <div className={ stl.how }>
        <h2>Как мы это делаем:</h2>
        <p>
          Обсуждаем приоритеты и делим работу на <br/>
          короткие отрезки длиной в&nbsp;неделю.
        </p>
        <p>
          В понедельник рассказываем, что будем делать,&nbsp;— <br/>
          в&nbsp;пятницу вы видите живой результат.
        </p>
      </div>

      <div className={ stl.why }>
        <h2>Почему это важно:</h2>
        <p>
          Быстрая скорость запуска — первая версия проекта <br/>
          может выйти уже через пару недель.
        </p>
        <p>
          Гибкость. Возможность менять требования на ходу. <br/>
          Прозрачность и&nbsp;полный контроль.
        </p>
      </div>
    </section>
  )
}

export default Banner