export const getAnimateClass = (isAnimate) => {
  return isAnimate && (` el-anim ${isAnimate}`)
}

export const observeAnimation = (elem, threshold) => {
  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show')
        observer.unobserve(entry.target)
      }
    })
  }

  let options = {
    threshold: threshold,
    rootMargin: '0px 0px -25px 0px',
    // rootMargin: '0px 0px -25px 0px',
  }

  const observer = new IntersectionObserver(callback, options)

  observer.observe(elem)
}

export const carouselAnimation = (carousel, inner, direction) => {
  // fallback for old browsers
  if (!('IntersectionObserver' in window)) return
  if (!carousel || !inner) return

  const carouselBox = carousel.getBoundingClientRect()
  const scrollableWidth = inner.scrollWidth - carousel.clientWidth
  const scrollableHeight = document.documentElement.clientHeight + carouselBox.height

  inner.style.transform = direction === 'toRight'
      ? `translateX(0)`
      : `translateX(-${scrollableWidth}px)`

  const scrollHandler = () => {
    const elemBottom = carousel.getBoundingClientRect().bottom
    const elemScroll = (elemBottom * scrollableWidth) / scrollableHeight

    const value = direction === 'toRight'
      ? scrollableWidth - elemScroll
      : elemScroll

    inner.style.transform = `translateX(-${value}px)`
  }

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        window.addEventListener('scroll', scrollHandler)
      } else {
        window.removeEventListener('scroll', scrollHandler)
      }
    })
  }

  const observer = new IntersectionObserver(callback)
  observer.observe(carousel)
}