import { useEffect, useRef } from 'react'
import ServiceTimeline from './ServiceTimeline/ServiceTimeline'
import stl from './ServiceItem.module.scss'
import { getAnimateClass, observeAnimation } from '@/utils/caseAnimation'

const ServiceItem = ({
  title,
  subtitle,
  timeline,
  text,
  animate = false
}) => {
  const elem = useRef()

  useEffect(() => animate && observeAnimation(elem.current, 0), [])

  return (
    <div className={ stl.item + getAnimateClass(animate) } ref={ elem }>
      <div className={ stl.head }>
        <h3 className={ stl.title }>{ title }</h3>
        <span className={ stl.subtitle }>{ subtitle }</span>
      </div>

      <ServiceTimeline steps={ timeline } />

      <div
        className={ stl.text }
        dangerouslySetInnerHTML={{__html: text}}
      />
    </div>
  )
}

export default ServiceItem