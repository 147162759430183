import stl from './Options.module.scss'

export default function Options({ list = [] }) {

  return (
    <ul className={ stl.list }>
      {
        list.map((item, index) => {
          const [title, description] = [...item]

          return (
            <li key={ `item-${index}` } className={ stl.item }>
              <span
                className={ stl.title }
                dangerouslySetInnerHTML={{__html: title}}
              />
              <span
                className={ stl.description }
                dangerouslySetInnerHTML={{__html: description}}
              />
            </li>
          )
        })
      }
    </ul>
  )
}