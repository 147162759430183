import Container from '@/components/Container/Container'
import Banner from '@/components/HomePage/Banner/Banner'
import MainVideo from '@/components/HomePage/MainVideo/MainVideo'
import ServiceItem from '@/components/HomePage/ServiceItem/ServiceItem'
import Options from '@/components/HomePage/Options/Options'
import stl from './Home.module.scss'


export async function getServerSideProps() {
  return { props: { ogTitle: "ProninTeam — студия интернет-решений" } }
}

export default function Home() {

  const services = [
    {
      title: 'Быстросайт',
      subtitle: 'Если задачу можно сделать за месяц',
      timeline: [
        ['Проектируем'],
        ['Создаем дизайн'],
        ['Пишем код'],
        ['Тестируем'],
        ['Осуществляем поддержку'],
      ],
      text:
      `Короткие проекты мы разрабатываем по waterfall, используя диаграмму Ганта. Это такой график, из которого вы всегда знаете, что мы делаем в каждый момент и когда ждать результат.<br><br>Оценку проекта сделаем уже в день обращения.\nА готовый сайт вы получите в течение месяца.<br><br>Бюджет: от 100 до 300 тысяч рублей.\nВсё максимально просто и прозрачно.`,
    },
    {
      title: 'Корпоративный сайт',
      subtitle: 'Когда проект займет больше месяца',
      timeline: [
        ['Ставим задачи на месяц'],
        ['Выполняем'],
        ['Отчитываемся'],
        ['Планируем следующий месяц']
      ],
      text:
      `Когда проект нельзя сделать за месяц,\nмы разбиваем его на несколько частей.<br><br>Обсуждаем, что должно быть сделано в первую очередь. Исполняем и сдаем.\nПараллельно оцениваем следующие задачи.<br><br>И так до тех пор, пока весь проект не будет готов.\nКаждый понедельник рассказываем про планы на неделю. А в пятницу показываем результат.<br><br>Бюджет: От 300 тысяч рублей до бесконечности.`,
    },
    {
      title: 'Аутсорс',
      subtitle: 'Расширить команду опытными IT специалистами',
      timeline: [
        ['Понимаем потребность'],
        ['Заключаем договор'],
        ['Работаем']
      ],
      text:
      `Из ваших потребностей понимаем, сколько\nи каких специалистов нужно в месяц.<br><br>Заключаем абонентский договор.\nИ все. У нас можно взять в аренду даже\nполтора программиста или половину дизайнера!<br><br>У вас есть нужные специалисты без беспокойства\nо затратах на инфраструктуру и прочих проблемах.\nОтчеты о работе — по вашим внутренним стандартам.`,
    },
  ]

  const optionsList = [
    [
      `Техническая\nподдержка сайта`,
      `Сделаем так, чтобы сайт работал постоянно и бесперебойно.<br><br>С резервным копированием и защитой от хакерских атак.`
    ],
    [
      `Информационная\nподдержка сайта`,
      `Наши специалисты проследят за актуальностью информации на сайте.<br><br>При необходимости обработают изображения, создадут баннеры, напишут тексты.`
    ],
    [
      `Продвижение сайта`,
      `Настроим контекстную рекламу и проведём поисковую оптимизацию сайта.`
    ]
  ]

  return (
    <Container types={ ['leftline', 'top'] }>

      {/* <PTHead
        ogTitle="ProninTeam — студия интернет-решений"
      /> */}

      <Banner/>

      <MainVideo />

      <div className="anchor" id="services" />
      <section className={ `section ${stl.services}` }>
        <h2 className={ stl.section_title }>
          Три варианта начала работ<br/>
          по поддержке и разработке сайта
        </h2>

        { services.map((item, i) => {
          return <ServiceItem
            key={`service-${i}`}
            animate="op-slide"
            { ...item }
          />
        })}
      </section>

      <section className="section">
        <h2 className={ stl.section_title }>
          Также можем предложить
        </h2>

        <Options list={ optionsList } />
      </section>
    </Container>
  )
}