import CloseSVG from '@/components/SVG/CloseSVG'
import { useRef, useEffect } from 'react'
import stl from './MainVideoModal.module.scss'

const MainVideoModal = ({ onClose }) => {

  const closeModal = () => {
    onClose()
    document.body.classList.remove('lock')
  }

  return (
    <>
      <div className={ stl.modal }>
        <iframe
          className={ stl.video }
          src="https://www.youtube.com/embed/XrvRTnz35pc?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />

        <button
          className={ stl.close_btn }
          onClick={ closeModal }
        >
          <CloseSVG />
        </button>
      </div>

      <div
        className={ stl.backdrop }
        onClick={ closeModal }
      />
    </>
  )
}

export default MainVideoModal